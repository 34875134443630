import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppWithAuth from './AppWithAuth';
import reportWebVitals from './reportWebVitals';
import Amplify from "aws-amplify";
import awsConfig from "./awsConfig";
import { createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";

Amplify.configure(awsConfig);

const theme = createMuiTheme({
  typography: {
    //fontFamily: ["-apple-system", "BlinkMacSystemFont", "AmazonEmber"].join(",")
    fontFamily: 'Poppins'
  },
  palette: {
    primary: { main: '#253551' },
    secondary: { main: '#5cb7e0' }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppWithAuth />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
