/*********************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance    *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://www.apache.org/licenses/LICENSE-2.0                                                                    *
 *                                                                                                                    *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 *********************************************************************************************************************/
import React, { Suspense, useState } from "react";

const App = React.lazy(() => import("App"));
const LandingPage = React.lazy(() =>
  import("landing-page/LandingPage")
);

const AppWithAuth = () => {
  // eslint-disable-next-line no-unused-vars
  const [authState, setAuthState] = useState("signedOut");
  const stateChangeHandler = state => setAuthState(state);
  var signedIn = localStorage.getItem('signedIn', "false") === "true";

  if (signedIn) {
    // check if token has expired
    const expires = localStorage.getItem('expires');
    if (new Date().getTime() > expires*1000)
      signedIn = false;
  }

  return (
    <Suspense fallback={<div />}>
        {signedIn ? <App /> : <LandingPage stateChangeHandler={stateChangeHandler} />}
    </Suspense>
  );
};

export default AppWithAuth;
